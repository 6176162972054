import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { Collection } from 'app/typings/collection';
import {
  CopywritingForm,
  PackageCover,
  PackageDisclaimer,
  PackageDiscover,
  PackageForm,
} from 'app/typings/packages';
import { apiMutation } from 'app/utils/request/api';

import {
  listPath,
  packageCopywritingPath,
  packageCoverPath,
  packageDisclaimersPath,
  packageDiscoveryPath,
} from './usePackages';

const savePath = 'packages' as const;
const hidePath = 'packages/:packageId/hide' as const;
const publishPath = 'packages/:packageId/publish' as const;
const deletePath = 'packages/:packageId' as const;
const copywritingPath = 'packages/:packageId/copywriting' as const;
const discoverPath = 'packages/:packageId/discover' as const;
const coverPath = 'packages/:packageId/cover' as const;
const disclaimerPath = 'packages/:packageId/disclaimers' as const;

export function useSavePackage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: PackageForm) =>
      apiMutation(
        'POST',
        {
          path: savePath,
        },
        payload
      ) as Promise<number>,
    onSuccess: (_) => {
      queryClient.invalidateQueries([listPath]);
      message.success(`Successfully save package`);
    },
    onError: (err) => {
      message.error(`Error saving package`);
    },
  });
}

export function useHidePackage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { packageId: number; hidden: boolean }) =>
      apiMutation(
        'PATCH',
        {
          path: hidePath,
          params: {
            packageId: payload.packageId.toString(),
          },
        },
        payload
      ) as Promise<{ hidden: boolean }>,
    onSuccess: (_, { packageId, hidden }) => {
      message.success(`Package #${packageId} ${hidden ? 'hidden' : 'shown'}`);

      queryClient.invalidateQueries([listPath]);
    },
    onError: (err, { packageId }) => {
      message.error(`Error hiding package ${packageId}`);
    },
  });
}

export function usePublishPackage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { packageId: number; published: boolean }) =>
      apiMutation(
        'PATCH',
        {
          path: publishPath,
          params: {
            packageId: payload.packageId.toString(),
          },
        },
        payload
      ) as Promise<{ published: boolean }>,
    onSuccess: (_, payload) => {
      message.success(
        `Package #${payload.packageId} ${
          payload.published ? 'published' : 'unpublished'
        }`
      );
      queryClient.invalidateQueries([listPath]);
    },
    onError: (err, payload) => {
      message.error(`Error publishing package ${payload.packageId}`);
    },
  });
}

export function useDeletePackage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { packageId: number }) =>
      apiMutation('DELETE', {
        path: deletePath,
        params: {
          packageId: payload.packageId.toString(),
        },
      }) as Promise<Collection>,
    onSuccess: (_, payload) => {
      message.success(`Package #${payload.packageId} deleted`);
      queryClient.invalidateQueries([listPath]);
    },
    onError: (err) => {
      message.error(`Not possible: this package is used somewhere else`);
    },
  });
}

export function useSavePackageCopywritting() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      packageId,
      payload,
    }: {
      packageId: number;
      payload: CopywritingForm;
    }) =>
      apiMutation(
        'PUT',
        {
          path: copywritingPath,
          params: {
            packageId: packageId.toString(),
          },
        },
        payload
      ) as Promise<number>,
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries([packageCopywritingPath, [packageId]]);
      message.success(`Copywritting for package #${packageId} saved`);
    },
    onError: (err) => {
      message.error(`Error saving Copywriting`);
    },
  });
}

export function useSavePackageDiscover() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      packageId,
      payload,
    }: {
      packageId: number;
      payload: PackageDiscover;
    }) =>
      apiMutation(
        'PUT',
        {
          path: discoverPath,
          params: {
            packageId: packageId.toString(),
          },
        },
        payload
      ) as Promise<number>,
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries([packageDiscoveryPath, [packageId]]);
      message.success(`Discover for package #${packageId} saved`);
    },
    onError: (err) => {
      message.error(`Error saving Discover`);
    },
  });
}

export function useSavePackageCover() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      packageId,
      payload,
    }: {
      packageId: number;
      payload: PackageCover;
    }) =>
      apiMutation(
        'PUT',
        {
          path: coverPath,
          params: {
            packageId: packageId.toString(),
          },
        },
        payload
      ) as Promise<number>,
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries([packageCoverPath, [packageId]]);
      message.success(`Covers for package #${packageId} saved`);
    },
    onError: (err) => {
      message.error(`Error saving Cover`);
    },
  });
}

export function useSavePackageDisclaimer() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      packageId,
      payload,
    }: {
      packageId: number;
      payload: PackageDisclaimer[];
    }) =>
      apiMutation(
        'PUT',
        {
          path: disclaimerPath,
          params: {
            packageId: packageId.toString(),
          },
        },
        payload
      ) as Promise<number>,
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries([packageDisclaimersPath, [packageId]]);
      message.success(`Disclaimers for package #${packageId} saved`);
    },
    onError: (err) => {
      message.error(`Error saving Disclaimers`);
    },
  });
}
