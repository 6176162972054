import { Layout, message } from 'antd';
import _ from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import URLSearchParams from 'url-search-params';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import {
  tryFetchHotelExperiences,
  trySaveHotelExperience,
} from 'app/redux/actions/experiences';
import {
  tryFetchHotel,
  trySaveHotel,
  trySaveServices,
} from 'app/redux/actions/hotels';
import {
  tryFetchHotelPictures,
  trySaveGallery,
} from 'app/redux/actions/pictures';
import {
  tryDeleteHotelRoom,
  tryFetchHotelRooms,
  trySaveHotelRoom,
} from 'app/redux/actions/rooms';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';
import { getUser } from 'app/redux/selectors/auth';
import { selectSpaceTypes } from 'app/redux/selectors/conf';
import { getHotel, getHotelsLoading } from 'app/redux/selectors/hotels';
import { selectOrderedByIdPictures } from 'app/redux/selectors/pictures';
import { getHotelRooms } from 'app/redux/selectors/rooms';

import ExperiencesForm from './ExperiencesForm/ExperiencesForm';
import FinancialForm from './FinancialForm/FinancialForm';
import HeaderContext from './HeaderContext/HeaderContext';
import './HotelBuilder.scss';
import MediaForm from './MediaForm/MediaForm';
import ProfileForm from './ProfileForm/ProfileForm';
import RoomsForm from './RoomsForm/RoomsForm';
import ServicesForm from './ServicesForm/ServicesForm';
import SideMenu from './SideMenu/SideMenu';
import SpacesForm from './SpacesForm/SpacesForm';
import ThankYou from './ThankYou/ThankYou';
import Welcome from './Welcome/Welcome';

type StepNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type Props = {
  location: {
    search: string;
  };
  match: {
    params: {
      hotelId: string;
    };
  };
};

const steps = [
  {
    key: 'profile',
    title: 'Profile',
    order: 1,
  },
  {
    key: 'services',
    title: 'Services',
    order: 2,
  },
  {
    key: 'media',
    title: 'Media',
    order: 3,
  },
  {
    key: 'spaces',
    title: 'Spaces',
    order: 4,
  },
  {
    key: 'rooms',
    title: 'Rooms',
    order: 5,
  },
  {
    key: 'experiences',
    title: 'Experiences',
    order: 6,
  },
  {
    key: 'financial_details',
    title: 'Financial details',
    order: 7,
  },
];

export const HotelBuilder = ({ location, match }: Props) => {
  const hotel = useAppSelector(getHotel);
  const isFetchHotelLoading = useAppSelector(getHotelsLoading);
  const pictures = useAppSelector(selectOrderedByIdPictures);
  const rooms = useAppSelector(getHotelRooms);
  const spaceTypes = useAppSelector(selectSpaceTypes);
  const user = useAppSelector(getUser);
  const [originalBillingEmails, setOriginalBillingsEmails] = useState(
    hotel?.billingEmails
  );
  const { track } = useAmplitude();

  const dispatch = useAppDispatch();

  const hotelId = parseInt(match.params.hotelId, 10);

  const [step, setStep] = useState<StepNumber>(0);
  const [validSteps, setValidSteps] = useState<any>({});

  const isAdmin =
    !!user && (user.role === 'admin' || user.role === 'superadmin');

  useEffect(() => {
    const stepFromUrl = new URLSearchParams(location.search).get('step');

    if (stepFromUrl) {
      // @ts-ignore
      setStep(stepFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(tryFetchHotelExperiences(hotelId, false));
    dispatch(tryFetchHotel({ hotelId }));
    dispatch(tryFetchHotelPictures(hotelId));
    dispatch(tryFetchHotelRooms(hotelId));
  }, [dispatch, hotelId]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!isFetchHotelLoading) {
      setOriginalBillingsEmails(hotel?.billingEmails);
    }
  }, [hotel?.billingEmails, isFetchHotelLoading]);

  const trackBuilderStep = () => {
    switch (step) {
      case 0:
        return track('Complete Hotel Builder', {
          stepCompleted: 'started',
        });
      case 1:
        return track('Complete Hotel Builder', {
          stepCompleted: 'profile',
        });
      case 2:
        return track('Complete Hotel Builder', {
          stepCompleted: 'services',
        });
      case 3:
        return track('Complete Hotel Builder', {
          stepCompleted: 'media',
        });
      case 4:
        return track('Complete Hotel Builder', {
          stepCompleted: 'spaces',
        });
      case 5:
        return track('Complete Hotel Builder', {
          stepCompleted: 'rooms',
        });
      case 6:
        return track('Complete Hotel Builder', {
          stepCompleted: 'experiences',
        });
      case 7:
        return track('Complete Hotel Builder', {
          stepCompleted: 'finance',
        });
      default:
        return null;
    }
  };

  const prev = () => {
    setStep((currentStep) => (currentStep - 1) as StepNumber);
  };

  const next = () => {
    if (!validSteps[step]) {
      trackBuilderStep();
    }

    setValidSteps({ ...validSteps, [step]: true });

    setStep((currentStep) => (currentStep + 1) as StepNumber);
  };

  const handleSaveHotel = (newValues: Partial<Hotel>) => {
    const newForm = { ...hotel, ...newValues } as Hotel;

    const emails = newForm.notifEmails.map(({ email }) => email);
    const hasDuplicateEmails = new Set(emails).size !== emails.length;

    if (hasDuplicateEmails) {
      message.error('Duplicate emails not allowed');

      return;
    }

    dispatch(trySaveHotel({ hotel: newForm }));

    next();
  };

  const renderForm = (hotelDefined: Hotel) => {
    switch (step) {
      case 0:
        return <Welcome handleNext={next} />;
      case 1:
        return (
          <ProfileForm
            handleSubmit={handleSaveHotel}
            initialValue={hotelDefined}
            originalBillingEmails={originalBillingEmails}
          />
        );
      case 2:
        return (
          <ServicesForm
            handleSubmit={(services) => {
              dispatch(trySaveServices({ hotelId, services }));
              next();
            }}
            initialValue={hotelDefined.services.services || []}
          />
        );
      case 3:
        return (
          <MediaForm
            initialValue={pictures}
            handleSubmit={(newPictures) => {
              dispatch(
                trySaveGallery({
                  id: hotelId,
                  name: 'unused',
                  pictures: newPictures,
                  kind: 'official',
                })
              );
              next();
            }}
          />
        );
      case 4:
        return (
          <SpacesForm
            spaceTypes={spaceTypes}
            hotel={hotelDefined}
            onSubmit={next}
          />
        );
      case 5:
        return (
          <RoomsForm
            rooms={rooms}
            hotel={hotelDefined}
            saveRoom={(room) => dispatch(trySaveHotelRoom(hotelId, room))}
            deleteRoom={(roomId) =>
              dispatch(tryDeleteHotelRoom(hotelId, roomId))
            }
            saveHotel={handleSaveHotel}
          />
        );
      case 6:
        return (
          <ExperiencesForm
            saveExperience={(experience) =>
              dispatch(trySaveHotelExperience(experience))
            }
            handleNext={next}
            hotelId={hotelId}
          />
        );
      case 7:
        return (
          <FinancialForm
            initialValues={hotelDefined}
            handleSubmit={handleSaveHotel}
            countryId={hotel?.address.countryId}
          />
        );
      case 8:
        return <ThankYou handleNext={() => {}} showButton={isAdmin} />;
      default:
        return null;
    }
  };

  if (_.isEmpty(hotel) || hotel?.id !== hotelId) {
    return null;
  }

  return (
    <Layout>
      <SideMenu steps={steps} step={step} validSteps={validSteps} />
      <div className="hotel-builder">
        <CurrencyWrapper hotel={hotel}>
          <HeaderContext.Provider value={{ steps, step, prev }}>
            {renderForm(hotel as Hotel)}
          </HeaderContext.Provider>
        </CurrencyWrapper>
      </div>
    </Layout>
  );
};

export default HotelBuilder;
