import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Layout, Switch, Tooltip } from 'antd';
import arrayMove from 'array-move';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import BasicInfo from 'app/components/commons/Package/General/BasicInfo/BasicInfo';
import SortableExperienceList from 'app/components/commons/Package/General/ExperienceList/ExperienceList';
import SortableRoomList from 'app/components/commons/Package/General/RoomList/RoomList';
import {
  ExperienceType,
  RoomType,
} from 'app/components/commons/Package/General/Types';
import Spinner from 'app/components/commons/Spinner';
import ToggleableInput from 'app/components/commons/ToggleableInput/ToggleableInput';
import CustomInput from 'app/components/fields/Input';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useHotels } from 'app/hooks/data/hotels/useHotel';
import { useHotelExperiences } from 'app/hooks/data/hotels/useHotelExperience';
import { useGetPackage } from 'app/hooks/data/packages/usePackages';
import { useSavePackage } from 'app/hooks/data/packages/useSavePackage';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { useRooms } from 'app/hooks/data/useRooms';
import { parseInputNumber } from 'app/utils/typing';

import './General.scss';

const { Content } = Layout;

type FormValues = {
  id: number;
  hotelId: number;
  slug: string;
  dayPackage: boolean;
  experiences: Array<ExperienceType>;
  rooms: Array<RoomType>;
  singleCustomerDiscount?: number;
  additionalAdultPrice?: number;
};

type Props = {
  headerMenu?: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | null;
};

export const General = ({ headerMenu, headerExtra, packageId }: Props) => {
  const { data: pkg, isLoading: pkgLoading } = useGetPackage(packageId);

  const [hotelId, setHotelId] = useState<number | null>(null);

  const { data: hotels, isLoading: hotelsLoading } = useHotels();

  const { data: rooms, isLoading: roomsLoading } = useRooms(hotelId);
  const { data: experienceCategories } = useExperiencesCategories();
  const { data: hotelExperiences, isLoading: expLoading } = useHotelExperiences(
    hotelId,
    { includeStaycationExp: true }
  );
  const { mutateAsync: savePackage } = useSavePackage();
  const packageName = pkg?.aliasForHotel;

  const history = useHistory();
  const [mode, setMode] = useState<'view' | 'edit'>(
    packageId ? 'view' : 'edit'
  );
  const [isDirtyMove, setIsDirtyMove] = useState(false);
  const [form] = Form.useForm();
  const { currencySymbol } = useContext(CurrencyContext);

  useEffect(() => {
    if (!pkgLoading && pkg) {
      setHotelId(pkg.hotelId);
    }
  }, [pkg, pkgLoading]);

  const onCancel = useCallback(() => {
    if (packageId) {
      setMode('view');
    } else {
      history.push('/packages');
    }
  }, [packageId, history]);

  const hotel = useMemo(() => {
    if (!hotelId) {
      return null;
    }

    return hotels?.find((h) => h.id === hotelId);
  }, [hotels, hotelId]);

  const formattedStaycationExperiences = useMemo(() => {
    if (!hotel) {
      return [];
    }

    const filtered = hotelExperiences?.filter(
      (exp) => exp.countryId === hotel.countryId
    );

    const parsed = filtered?.map((exp) => {
      const packageExperience = pkg?.experiences.find((e) => e.id === exp.id);

      return {
        ...packageExperience,
        ...exp,
        title: computeExperienceName({
          category: experienceCategories?.find(
            (cat) => cat.id === exp.categoryId
          ),
          ...exp,
        }),
        description: undefined,
        avatar: exp.coverPicture,
        included: packageExperience?.included ? 'Included' : 'Add-on',
        pictures: exp.pictures,
        id: exp.id,
      };
    });

    return parsed;
  }, [hotelExperiences, hotel, pkg?.experiences, experienceCategories]);

  const formattedHotelExperiences = useMemo(() => {
    if (!hotel) {
      return [];
    }

    const filtered = hotelExperiences?.filter(
      (exp) => exp.hotelId === hotel.id
    );

    const parsed = filtered?.map((exp) => {
      const packageExperience = pkg?.experiences.find((e) => e.id === exp.id);

      return {
        ...packageExperience,
        ...exp,
        title: computeExperienceName({
          category: experienceCategories?.find(
            (cat) => cat.id === exp.categoryId
          ),
          ...exp,
        }),
        description: undefined,
        avatar: exp.coverPicture,
        included: packageExperience?.included ? 'Included' : 'Add-on',
        pictures: exp.pictures,
        id: exp.id,
      };
    });

    return parsed;
  }, [pkg, experienceCategories, hotelExperiences, hotel]);

  const formattedRooms = useMemo(
    () =>
      rooms?.map((room) => ({
        ...room,
        title: room.name || room.categoryName,
        description: room.name ? room.categoryName : undefined,
        avatar: room.pictures[0],
        id: room.id,
      })),
    [rooms]
  );

  const formattedValues = useMemo(
    () =>
      packageId && pkg && formattedStaycationExperiences
        ? {
            ...pkg,
            experiences: pkg.experiences.map((exp) => {
              const expFull = formattedHotelExperiences
                ?.concat(formattedStaycationExperiences)
                ?.find((e) => e.id === exp.id);

              return { ...exp, ...expFull };
            }),
            rooms: pkg.rooms
              .map((room) => formattedRooms?.find((r) => r.id === room))
              .filter((room) => !!room),
          }
        : {
            experiences: [],
            rooms: [],
            dayPackage: false,
            additionalAdultPrice: undefined,
            additionalChildPrice: undefined,
            singleCustomerDiscount: undefined,
            childrenAllowed: true,
          },
    [
      packageId,
      pkg,
      formattedHotelExperiences,
      formattedStaycationExperiences,
      formattedRooms,
    ]
  );

  // We want to reset the form every time the formattedValues changes,
  // because it means one of the requests (getHotelExperiences, getHotelRooms... ) was resolved.
  // If we don't do it, the form will be filled with incomplete values.
  // To avoid this kind of tricks, we could start to render the form only after all the requests are resolved.
  useEffect(() => {
    if (packageId && formattedValues) {
      form.resetFields();
    }
  }, [packageId, formattedValues, form]);

  const checkIncluded = (values: FormValues) =>
    !values.experiences?.length ||
    values.experiences?.some(
      (exp: ExperienceType) => exp.included === 'Included'
    );

  const isLoading =
    hotelsLoading || (!!pkg && (pkgLoading || expLoading || roomsLoading));

  const paxOptionsObject = useMemo(
    () => ({
      additionalAdultPrice: {
        title: 'Fee for extra adult',
        value: formattedValues.additionalAdultPrice,
        name: 'additionalAdultPrice',
        wordingFirstPart: 'Apply an additional fee of',
        wordingSecondPart: 'per extra adult guest',
      },
      singleCustomerDiscount: {
        title: 'Single guest discount',
        value: formattedValues.singleCustomerDiscount,
        name: 'singleCustomerDiscount',
        wordingFirstPart: 'Apply a discount of',
        wordingSecondPart: 'for single guest',
      },
      additionalChildPrice: {
        title: 'Fee for extra child',
        value: formattedValues.additionalChildPrice,
        name: 'additionalChildPrice',
        wordingFirstPart: 'Apply an additional fee of',
        wordingSecondPart: 'per extra child guest',
      },
    }),
    [
      formattedValues.additionalAdultPrice,
      formattedValues.additionalChildPrice,
      formattedValues.singleCustomerDiscount,
    ]
  );

  const renderPaxOptionInput = useCallback(
    (
      paxOption:
        | 'additionalAdultPrice'
        | 'singleCustomerDiscount'
        | 'additionalChildPrice'
    ) => {
      return (
        <div className="input-value">
          {mode === 'view' ? (
            <div>
              {form.getFieldValue([paxOption]) ?? formattedValues[paxOption]}
              {currencySymbol}
            </div>
          ) : (
            <>
              {paxOptionsObject?.[paxOption].wordingFirstPart}
              <Form.Item name={paxOption} noStyle>
                <CustomInput
                  suffix={currencySymbol}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  parser={parseInputNumber}
                />
              </Form.Item>
            </>
          )}
          {paxOptionsObject?.[paxOption].wordingSecondPart}
        </div>
      );
    },
    [mode, form, formattedValues, currencySymbol, paxOptionsObject]
  );

  const renderSwitchableInput = useCallback(
    (
      paxOption:
        | 'additionalAdultPrice'
        | 'singleCustomerDiscount'
        | 'additionalChildPrice'
    ) => {
      return (
        <div className="sectionContainer">
          <div>{paxOptionsObject?.[paxOption].title}</div>
          <ToggleableInput
            value={form.getFieldValue(paxOption)}
            disabled={mode === 'view'}
            name={paxOptionsObject?.[paxOption].name}
          >
            {renderPaxOptionInput(paxOption)}
          </ToggleableInput>
        </div>
      );
    },
    [form, mode, paxOptionsObject, renderPaxOptionInput]
  );

  const onFinish = useCallback(
    async (values: FormValues) => {
      const formattedPkg = {
        ...values,
        experiences: values.experiences.map((exp: any) => ({
          ...exp,
          discover: exp.discover || false,
          included: exp.included === 'Included',
        })),
        rooms: values.rooms.map((room) => room.id),
        id: packageId ? packageId : undefined,
      };

      savePackage(formattedPkg);
      setMode('view');
    },
    [packageId, savePackage]
  );

  return (
    <FormLegacyRenderProp
      form={form}
      initialValues={formattedValues}
      onFinish={onFinish}
      className="general-form"
    >
      {(
        values,
        {
          resetFields,
          isFieldsTouched,
          submit,
          setFieldsValue,
          getFieldsError,
          setFieldValue,
        }
      ) => (
        <SDetailLayout
          title={
            packageId && pkg
              ? packageName && packageName.length
                ? packageName
                : `Package #${pkg.id}`
              : 'New Package'
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={() => {
            submit();
            setIsDirtyMove(false);
          }}
          onCancel={() => {
            onCancel();
            setIsDirtyMove(false);
          }}
          reset={resetFields}
          isDirty={isDirtyMove || isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          disabled={mode === 'edit' && !checkIncluded(values)}
          isValid={getFieldsError().every((item) => item.errors.length > 0)}
        >
          <Content className="package-detail__content">
            <Spinner spinning={isLoading}>
              <SCard title="Basic information">
                <BasicInfo
                  mode={mode}
                  values={values}
                  newHotel={!packageId}
                  setFieldValue={setFieldValue}
                  onHotelSelect={(id: number) => setHotelId(id)}
                />
              </SCard>
              {!!values.hotelId && !!hotelExperiences && !!rooms && (
                <>
                  <SCard title="Experiences">
                    <SortableExperienceList
                      mode={mode}
                      selectedExperiences={values?.experiences}
                      hotelExperiences={formattedHotelExperiences}
                      staycationExperiences={formattedStaycationExperiences}
                      move={({ oldIndex, newIndex }) => {
                        setIsDirtyMove(true);
                        setFieldsValue({
                          experiences: arrayMove(
                            values.experiences,
                            oldIndex,
                            newIndex
                          ),
                        });
                      }}
                    />
                  </SCard>
                  <SCard title="Rooms">
                    <SortableRoomList
                      mode={mode}
                      rooms={values?.rooms}
                      allRooms={formattedRooms}
                      move={({ oldIndex, newIndex }) => {
                        setIsDirtyMove(true);
                        setFieldsValue({
                          rooms: arrayMove(values.rooms, oldIndex, newIndex),
                        });
                      }}
                    />
                  </SCard>
                  <SCard
                    title="PAX options"
                    subtitle="This fee/discount will be applied to the total price (package price + room price)"
                  >
                    <div className="container">
                      <div className="adult-section">
                        <div className="section-title">
                          Adults
                          <Tooltip title={`Over 12 years old`}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                        <div className="section-switch">
                          {renderSwitchableInput('additionalAdultPrice')}
                          {renderSwitchableInput('singleCustomerDiscount')}
                        </div>
                      </div>
                      <div className="chidren-section">
                        <div className="section-title">
                          Children
                          <Tooltip title={`Between 2 and 12 years old`}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>

                        <div className="section-switch">
                          <div className="sectionContainer">
                            <div>Allow children</div>
                            <Form.Item
                              noStyle
                              name={'childrenAllowed'}
                              valuePropName={
                                pkg?.childrenAllowed ? 'checked' : 'unchecked'
                              }
                            >
                              <Switch
                                disabled={mode === 'view'}
                                onChange={() => {
                                  form.setFieldValue(
                                    'additionalChildPrice',
                                    undefined
                                  );
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {form.getFieldValue('childrenAllowed') && (
                          <div className="section-switch">
                            {renderSwitchableInput('additionalChildPrice')}
                          </div>
                        )}
                      </div>
                    </div>
                  </SCard>
                </>
              )}
            </Spinner>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

export default General;
