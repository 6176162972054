import { Form, Layout, Switch } from 'antd';
import { ReactNode, useCallback, useState } from 'react';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useHotelExperiences } from 'app/hooks/data/hotels/useHotelExperience';
import { useGetPackageDiscover } from 'app/hooks/data/packages/usePackages';
import { useSavePackageDiscover } from 'app/hooks/data/packages/useSavePackage';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { Experience } from 'app/typings/experiences';
import { Package, PackageDiscover } from 'app/typings/packages';

import './Discover.scss';

const { Content } = Layout;

type FormValues = PackageDiscover;

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  pkg: Package;
};

export const Discover = ({ pkg, headerMenu, headerExtra }: Props) => {
  const { data: experienceCategories } = useExperiencesCategories();

  const { data: discover } = useGetPackageDiscover(pkg.id);
  const { data: hotelExperiences } = useHotelExperiences(pkg.hotelId, {
    includeStaycationExp: true,
  });
  const { mutateAsync: saveDiscover } = useSavePackageDiscover();

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (values: FormValues) => {
      if (pkg && pkg.id) {
        await saveDiscover({ packageId: pkg.id, payload: values });
        setMode('view');
      }
    },
    [pkg, saveDiscover]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (!pkg || !discover) {
    return null;
  }

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={discover}
      className="discover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit }) => (
        <SDetailLayout
          title={pkg?.aliasForHotel ?? `Package #${pkg.id}`}
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
        >
          <Content className="package-detail__content">
            <SCard title="Introduction" className="scard-with-pictures">
              <Form.Item name="introPictures">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Introduction"
                    hotelId={pkg.hotelId}
                    picturesCategories={[
                      { name: 'Official', type: 'official' },
                      { name: 'Sourcing', type: 'sourcing' },
                    ]}
                    kind="sourcing"
                    draggable
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the intro part"
                  />
                ) : (
                  <UploadList
                    pictures={values.introPictures}
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <Form.List name="packageExperiences">
              {(fields) =>
                fields.map((field, idx) => {
                  const experience = values.packageExperiences[idx];

                  if (!experience.discover && mode === 'view') {
                    return null;
                  }

                  const title = computeExperienceName({
                    category: experienceCategories?.find(
                      (cat) => cat.id === experience.categoryId
                    ),
                    ...(hotelExperiences?.find(
                      (exp) => exp.id === experience.id
                    ) as Experience),
                  });

                  return (
                    <SCard
                      title={title}
                      isExpanded={experience.discover}
                      leftHeader={
                        mode === 'edit' ? (
                          <Form.Item
                            name={[field.name, 'discover']}
                            valuePropName="checked"
                          >
                            <Switch size="small" />
                          </Form.Item>
                        ) : undefined
                      }
                      className="scard-with-pictures"
                    >
                      <Form.Item {...field} name={[field.name, 'pictures']}>
                        {mode === 'edit' ? (
                          <Pictures
                            sectionTitle={title}
                            hotelId={pkg.hotelId}
                            picturesCategories={[
                              { name: 'Official', type: 'official' },
                              { name: 'Sourcing', type: 'sourcing' },
                            ]}
                            kind="sourcing"
                            draggable
                            editable
                            removable
                            modalSubtitle="Select photos or upload new ones to illustrate the experience"
                          />
                        ) : (
                          <UploadList
                            pictures={experience.pictures}
                            uploadingItems={{}}
                            previewable
                          />
                        )}
                      </Form.Item>
                    </SCard>
                  );
                })
              }
            </Form.List>
            <SCard
              title="Rooms"
              className="staycation-card-last scard-with-pictures"
            >
              <Form.Item name="roomPictures">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Rooms"
                    hotelId={pkg.hotelId}
                    picturesCategories={[
                      { name: 'Photos List', type: 'sourcing' },
                    ]}
                    kind="sourcing"
                    draggable
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the rooms"
                    fetchRooms
                  />
                ) : (
                  <UploadList
                    pictures={values.roomPictures}
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

export default Discover;
