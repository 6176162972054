import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { NamePath } from 'rc-field-form/lib/interface';
import { useCallback } from 'react';

import { Option, Select } from 'app/components/fields/Select';
import { useHotels } from 'app/hooks/data/hotels/useHotel';

import './BasicInfo.scss';

const RULES = [{ required: true, message: 'Required' }];

type Props = {
  mode?: string;
  values?: any;
  newHotel: boolean;
  onHotelSelect: (hotelId: number) => void;
  setFieldValue: (name: NamePath, value: any) => void;
};

export const BasicInfo = ({
  mode = 'edit',
  values,
  newHotel,
  onHotelSelect,
  setFieldValue,
}: Props) => {
  const { data: hotels } = useHotels();

  const onChangeHotel = useCallback(
    (id: number) => {
      onHotelSelect(id);
    },
    [onHotelSelect]
  );

  if (!hotels) {
    return null;
  }

  return (
    <div className="basic-info">
      <div
        className={`input-row input-row-hotel-select ${
          mode === 'view' ? 'input-row-no-rules' : ''
        }`}
      >
        <Form.Item name="hotelId" label="Hotel" rules={RULES}>
          {mode === 'edit' ? (
            <Select
              placeholder="Select a hotel"
              showSearch
              optionFilterProp="children"
              onChange={onChangeHotel}
              disabled={!newHotel}
            >
              {hotels.map((hotel) => (
                <Option key={hotel.id} value={hotel.id}>
                  {hotel.name}
                </Option>
              ))}
            </Select>
          ) : (
            <div className="view-text">
              {hotels.find((hotel) => hotel.id === values.hotelId)?.name}
            </div>
          )}
        </Form.Item>
      </div>
      <div
        className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
      >
        {!newHotel && (
          <Form.Item name="slug" label="Slug">
            {mode === 'edit' || !values ? (
              <Input disabled />
            ) : (
              <div className="view-text">{values.slug}</div>
            )}
          </Form.Item>
        )}
        <Form.Item
          name="aliasForHotel"
          label="Alias for hotel"
          rules={RULES}
          extra={
            mode === 'edit' || !values ? (
              <span className="sub-text">
                <InfoCircleOutlined className="info-circle" />
                Shown on the Extranet and Bills (E.g. Expérience Spa, Expérience
                dîner)
              </span>
            ) : (
              <></>
            )
          }
        >
          {mode === 'edit' || !values ? (
            <Input />
          ) : (
            <div className="view-text">{values.aliasForHotel}</div>
          )}
        </Form.Item>
        <Form.Item
          name="aliasForUser"
          label="Alias for user"
          extra={
            mode === 'edit' || !values ? (
              <span className="sub-text">
                <InfoCircleOutlined className="info-circle" />
                Shown on Package page and Search results. If this is the hotel's
                only package, leave empty.
              </span>
            ) : (
              <></>
            )
          }
        >
          {mode === 'edit' || !values ? (
            <Input disabled={values.dayPackage} />
          ) : (
            <div className="view-text">{values.aliasForUser}</div>
          )}
        </Form.Item>
      </div>
      <div
        className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
      >
        <Form.Item
          name="dayPackage"
          label="Day Package"
          valuePropName="checked"
        >
          <Checkbox
            disabled={mode !== 'edit'}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('aliasForUser', undefined)
            }
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default BasicInfo;
