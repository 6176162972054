import { RuleObject } from 'antd/lib/form';

export const generateVatValidator =
  (countrySlug = 'FR', isVATRequired = false) =>
  (_: RuleObject, value: string) => {
    const isValidVAT =
      vatPatternsByCountrySlug[countrySlug]?.test(value) ?? false;

    if (isValidVAT || (value.length === 0 && !isVATRequired)) {
      return Promise.resolve();
    }

    return Promise.reject('Must be a valid VAT number');
  };

const vatPatternsByCountrySlug: Record<string, RegExp> = {
  FR: RegExp(/^FR[A-HJ-NP-Z0-9]{2}\d{9}$/),
  GB: RegExp(/^GB\d{9}$|^GB\d{12}$|^GBGD\d{3}$|^GBHA\d{3}$/),
  MC: RegExp(/^FR[A-HJ-NP-Z0-9]{2}\d{9}$/),
  BE: RegExp(/^BE[01]\d{9}$/),
  CH: RegExp(/^(CHE)-?(\d{9}|(\d{3}.\d{3}.\d{3}))( ?)(MWST|TVA|IVA)?$/),
  ES: RegExp(/^ES[A-Z]\d{7}[A-Z]$|^ES[A-Z][0-9]{7}[0-9A-Z]$|^ES[0-9]{8}[A-Z]$/),
  LU: RegExp(/^LU\d{8}$/),
  NL: RegExp(/^NL\d{9}B\d{2}$/),
  PT: RegExp(/^PT\d{9}$/),
  DE: RegExp(/^EL\d{9}$/),
  IT: RegExp(/^IT\d{11}$/),
};
