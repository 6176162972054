import { Button, Checkbox, Form, Input, Modal, Popconfirm, Radio } from 'antd';
import _ from 'lodash';
import { useContext, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { Option, Select } from 'app/components/fields/Select';
import { useBookingRefundParams } from 'app/hooks/data/adminBookingDetails/useAdminBookingRefund';
import { useCancellationReasons } from 'app/hooks/data/useCancellationReasons';
import { RefundForm } from 'app/typings/adminBookings/bookingRefund';

import './RefundModal.scss';

const RULES = [{ required: true, message: 'Required' }];

const defaultFormValues: RefundForm = {
  source: 'HOTEL',
  cancelBooking: false,
  amountCb: 0,
  amountGiftCard: 0,
  amountCredit: 0,
};

type Props = {
  bookingCode: string;
  title: string;
  onOk: (values: RefundForm) => void;
  onCancel: () => void;
};

export const RefundModal = ({ bookingCode, title, onOk, onCancel }: Props) => {
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);
  const [form] = Form.useForm();

  const { data: refundParams } = useBookingRefundParams(bookingCode);
  const { data: refundReasons } = useCancellationReasons();

  const [formValues, handleFormValues] =
    useState<RefundForm>(defaultFormValues);

  const merge = (values: any) => {
    const updatedForm = _.merge(formValues, values);

    handleFormValues({ ...updatedForm });
  };

  const onSubmit = () => {
    onOk(formValues);
    onCancel();
  };

  const renderAlreadyPaid = () =>
    refundParams?.alreadyRefunded ? (
      <div>
        Already refunded payment:{' '}
        {currencyFormatter(refundParams?.alreadyRefunded)}
      </div>
    ) : (
      <span></span>
    );

  if (!bookingCode || !refundParams) {
    return null;
  }

  const totalRefundAmount =
    (formValues.amountCb ?? 0) +
    (formValues.amountGiftCard ?? 0) +
    (formValues.amountCredit ?? 0);

  const hotelAmount =
    formValues.source === 'HOTEL'
      ? Math.min(
          refundParams.maxHotelAmount,
          totalRefundAmount * (1 - refundParams.commissionPercent)
        )
      : 0;
  const staycationAmount = totalRefundAmount - hotelAmount;

  return (
    <Modal
      width={560}
      open
      title={title}
      onCancel={onCancel}
      okText="Cancel"
      className="refund-modal"
      wrapClassName="admin-modal"
      centered
      // Popconfirm doesn't watch if button is disabled or not
      // https://github.com/ant-design/ant-design/issues/14788
      footer={
        <Popconfirm
          title="Are you sure you want to refund this booking ?"
          placement="bottomRight"
          onConfirm={() => form.submit()}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">Confirm</Button>
        </Popconfirm>
      }
    >
      <Form
        onValuesChange={(v, h) => merge(h)}
        initialValues={defaultFormValues}
        colon={false}
        form={form}
        onFinish={onSubmit}
      >
        <div className="input-row">
          Total Amount: {currencyFormatter(refundParams?.initialPayment)}
          {renderAlreadyPaid()}
        </div>
        <div className="input-row">
          <Form.Item name="source" label="Source">
            <Radio.Group>
              <Radio value="HOTEL">Hotel</Radio>
              <Radio value="STAYCATION">Staycation</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item
            name="amountCb"
            label="Refund on CB"
            help={'Max ' + currencyFormatter(refundParams?.maxCbRefund)}
          >
            {currencyInput({ min: 0, max: refundParams?.maxCbRefund })}
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item
            name="amountGiftCard"
            label="Refund on gift card"
            rules={RULES}
            help={'Max ' + currencyFormatter(refundParams?.maxGiftCardRefund)}
          >
            {currencyInput({ min: 0, max: refundParams?.maxGiftCardRefund })}
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item
            name="amountCredit"
            label="Refund on credit"
            rules={RULES}
            help={'Max ' + currencyFormatter(refundParams?.maxCreditRefund)}
          >
            {currencyInput({ min: 0, max: refundParams?.maxCreditRefund })}
          </Form.Item>
        </div>

        <div className="input-row">
          <div>
            Total Refund Amount: {currencyFormatter(totalRefundAmount)} (
            {currencyFormatter(hotelAmount)} from Hotel and{' '}
            {currencyFormatter(staycationAmount)} from Staycation)
          </div>
        </div>

        <div className="input-row">
          <Form.Item
            name="cancelBooking"
            valuePropName="checked"
            label="Cancellation?"
          >
            <Checkbox>Yes, booking has to be cancelled</Checkbox>
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item name="reason" label="Reason type" rules={RULES}>
            <Select placeholder="Choose a reason">
              {refundReasons?.map((reason) => (
                <Option key={reason.id} value={reason.id}>
                  {reason.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item name="comment" label="Comment">
            <Input.TextArea placeholder="Optional" rows={4} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default RefundModal;
