import { AppstoreAddOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';
import DotIcon from 'app/components/commons/icons/DotIcon';
import { useGetHotel } from 'app/hooks/data/hotels/useHotel';
import { useGetHotelPackages } from 'app/hooks/data/packages/usePackageExtranet';
import { useBedCategories } from 'app/hooks/data/rooms/useBedCategories';
import { useRoomCategories } from 'app/hooks/data/rooms/useRoomCategories';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminHotelId } from 'app/redux/selectors/hotelAdmin';
import { ExtranetPackage } from 'app/typings/packages';

import EmptyState from '../commons/EmptyState/EmptyState';
import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import './ExtranetPackages.scss';
import PackageDetail, {
  PackageDetailProps,
} from './_components/PackageModal/PackageDetail';
import PackageModal from './_components/PackageModal/PackageModal';
import PackageModalTitle from './_components/PackageModal/PackageModalTitle';
import PackagesTabs from './_components/PackageTabs/PackagesTabs';
import PackageUnpublishedAlert from './_components/PackageUnpublishedAlert';
import TableRowExperiences from './_components/TableRowExperiences';
import TableRowRooms from './_components/TableRowRooms';

const PAGE = 'Packages';

type PackagesInfoProps = {
  hotelId: number;
};

const PackagesInfo = ({ hotelId }: PackagesInfoProps) => {
  const { data: packagesList, isLoading } = useGetHotelPackages(hotelId);

  const { data: bedCategories } = useBedCategories();
  const { data: roomCategories } = useRoomCategories();
  const { data: experienceCategories } = useExperiencesCategories();

  const [activePackage, setActivePackage] = useState<ExtranetPackage>();

  const addOnsList =
    activePackage?.experiences.filter((item) => item.included === false) ?? [];

  const experiencesList =
    activePackage?.experiences.filter((item) => item.included === true) ?? [];

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [activeDataModal, setActiveDataModal] = useState<
    PackageDetailProps | undefined
  >();

  useEffect(() => {
    if (!isLoading) {
      setActivePackage(packagesList?.packages[0]);
    }
  }, [isLoading, packagesList?.packages]);

  const items = useMemo(() => {
    if (!packagesList || packagesList.packages.length === 0) {
      return [];
    }

    return packagesList.packages.map((pkg) => ({
      label: pkg.published ? (
        <span key={pkg.id}>{`Package - ${pkg.aliasForHotel}`}</span>
      ) : (
        <span className="unpublished-package" key={pkg.id}>
          {`Package - ${pkg.aliasForHotel}`}
          <TagWithIcon
            text={pkg.published ? 'Published' : 'Unpublished'}
            icon={
              <DotIcon
                color={pkg.published ? '#28A745' : '#808080'}
                width={6}
                height={6}
              />
            }
            type={pkg.published ? 'success' : 'default'}
          />
        </span>
      ),
      key: pkg.slug,
    }));
  }, [packagesList]);

  const onChangeTabs = (slug: string) => {
    if (!packagesList) {
      return [];
    }

    setActivePackage(packagesList.packages.find((pkg) => pkg.slug === slug));
  };

  if (!packagesList || isLoading) {
    return <></>;
  }

  const hasPackage = packagesList.packages.length > 0;

  const showExtranetHeader = () => {
    if (hasPackage) {
      return (
        <ExtranetHeader
          page={PAGE}
          withPackageButton={packagesList.packages.length === 1}
          packageSlug={packagesList.packages[0].slug}
        />
      );
    }

    return <ExtranetHeader page={PAGE} />;
  };

  return (
    <Layout className="extranet-table">
      {showExtranetHeader()}
      <div
        className={classNames(['extranet-package', { noContent: !hasPackage }])}
      >
        {hasPackage ? (
          <>
            {packagesList.packages.length > 1 && (
              <PackagesTabs
                onChangeTabs={onChangeTabs}
                items={items}
                activeSlug={activePackage?.slug}
              />
            )}

            {!activePackage?.published && <PackageUnpublishedAlert />}

            <div className="extranet-package-container">
              <TableRowExperiences
                title="Experiences"
                isDayPackage={activePackage?.dayPackage}
                experiencesList={experiencesList}
                experienceCategories={experienceCategories}
                setActiveDataModal={setActiveDataModal}
                setIsDetailModalOpen={setIsDetailModalOpen}
              />

              <TableRowExperiences
                title="Add-ons"
                isDayPackage={activePackage?.dayPackage}
                experiencesList={addOnsList}
                experienceCategories={experienceCategories}
                setActiveDataModal={setActiveDataModal}
                setIsDetailModalOpen={setIsDetailModalOpen}
              />

              <TableRowRooms
                title={activePackage?.dayPackage ? 'Slots' : 'Rooms'}
                isDayPackage={activePackage?.dayPackage}
                rooms={activePackage?.rooms ?? []}
                roomCategories={roomCategories ?? []}
                bedCategories={bedCategories ?? []}
                setActiveDataModal={setActiveDataModal}
                setIsDetailModalOpen={setIsDetailModalOpen}
              />
            </div>
          </>
        ) : (
          <EmptyState
            title="No published packages"
            description="Please contact your Account Manager for more information."
            icon={<AppstoreAddOutlined />}
          />
        )}
      </div>
      <PackageModal
        isDetailModalOpen={isDetailModalOpen}
        setIsDetailModalOpen={setIsDetailModalOpen}
        title={activeDataModal && <PackageModalTitle {...activeDataModal} />}
      >
        {activeDataModal && <PackageDetail {...activeDataModal} />}
      </PackageModal>
    </Layout>
  );
};

const ExtranetPackages = () => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  const { data: hotel } = useGetHotel(hotelId);

  if (!hotelId) {
    return <></>;
  }

  return (
    <CurrencyWrapper hotelId={hotelId} hotel={hotel}>
      <PackagesInfo hotelId={hotelId} />
    </CurrencyWrapper>
  );
};

export default ExtranetPackages;
